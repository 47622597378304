import React, { useState } from "react";
import houseRoof from './../assets/house-roof.svg';
import commercialRoof from './../assets/commercial-roof.svg';
import roofRepairs from './../assets/roof-repair.svg';
import trustOne from './../assets/trust-0.jpg';
import trustTwo from './../assets/trust-1.jpg';
import trustThree from './../assets/trust-2.jpg';
import { Helmet } from 'react-helmet-async';

export default function AboutUs() {

  return (
    <>
      <Helmet>
          <title>Cambridge Aspect Roofing | About Us</title>
          <meta name='description' content='Your roofing experts based in Cambridge. Contact us for a free no obligation quote.' />
          <link rel="canonical" href={"https://www.cambridgeaspectroofing.co.uk/about-us"} />
      </Helmet>
      <div className="bg-services-header h-[200px] md:h-[250px] lg:h-[40vh] bg-[#333] bg-blend-multiply relative bg-center bg-cover bg-no-repeat mt-[30px] lg:mt-[40px] p-10">
        <div className="absolute left-0 top-[calc(50%+20px)] -translate-y-1/2 text-center w-full">
          <h1 className="text-2xl sm:text-3xl md:text-5xl lg:text-5xl font-bold tracking-wider text-white">About us</h1>
        </div>
      </div>
      <div className="max-w-[1200px] mx-auto mb-20 px-5 sm:px-10">
        <div className="py-5 md:py-10 lg:py-18 border-b border-black/20 mb-10">
          <h2 className="text-sm lg:text-base uppercase tracking-widest mb-5 font-medium">Based in Cambridge</h2>
          <h1 className="text-3xl sm:text-4xl lg:text-5xl font-bold">Your roofing experts</h1>
        </div>
        <div className="flex flex-wrap gap-10">
          <div className="flex-1 flex flex-wrap gap-10 relative">
            <div className="rounded-lg overflow-hidden flex-1 h-fit">
              <img src={trustOne} alt="Cambridge Aspect Roofing"/>
            </div>
            <div className="flex-1">
              <div className="mb-10 rounded-lg overflow-hidden">
                <img src={trustTwo} alt="Cambridge Aspect Roofing"/>
              </div>
              <div className="rounded-lg overflow-hidden">
                <img src={trustThree} alt="Cambridge Aspect Roofing"/>
              </div>
            </div>
            <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 bg-[#4476C9] text-white rounded-lg p-5">
              <h3 className="text-2xl font-bold">Experts you can trust</h3>
            </div>
          </div>
          <div className="w-full lg:w-1/2">
          <div className="min-w-[300px] flex flex-wrap bg-white rounded-lg gap-x-5 gap-y-5 justify-between mb-5 lg:mb-0 p-4 items-start content-start">
            <div className="w-full lg:w-14 pt-1 flex justify-start pb-4 h-[80px] lg:h-auto">
              <img src={houseRoof} className="w-12 h-14 lg:w-full lg:h-auto"/>
            </div>
            <div className="flex-1">
              <h2 className="mb-4 font-bold text-lg leading-6">Earliest Consultation</h2>
              <p>Protect your home with our durable and aesthetically pleasing roofing solutions. From installations to repairs, we offer a range of services tailored to meet the unique needs of homeowners.</p>
            </div>
          </div>
          <div className="min-w-[300px] flex flex-wrap bg-white rounded-lg gap-x-5 gap-y-5 justify-between mb-5 lg:mb-0 p-4 items-start content-start">
            <div className="w-full lg:w-14 pt-1 flex justify-start pb-4 h-[80px] lg:h-auto">
              <img src={commercialRoof} className="w-12 h-14 lg:w-full lg:h-auto"/>
            </div>
            <div className="flex-1">
              <h2 className="mb-4 font-bold text-lg leading-6">Commercial Roofing</h2>
              <p>Ensure your business is safeguarded with our expert commercial roofing services. We provide installations, maintenance, and repairs for a variety of commercial roofing systems, ensuring minimal disruption to your operations.</p>
            </div>
          </div>
          <div className="min-w-[300px] flex flex-wrap bg-white rounded-lg gap-x-5 gap-y-5 justify-between mb-5 lg:mb-0 p-4 items-start content-start">
            <div className="w-full lg:w-14 pt-1 flex justify-start pb-4 h-[80px] lg:h-auto">
              <img src={roofRepairs} className="w-12 h-14 lg:w-full lg:h-auto"/>
            </div>
            <div className="flex-1">
              <h2 className="mb-4 font-bold text-lg leading-6">Roof Repairs and Maintenance</h2>
              <p>Keep your roof in top condition with our comprehensive repair and maintenance services. Whether it’s a minor leak or major damage, our skilled team is ready to restore your roof’s integrity.</p>
            </div>
          </div>
          </div>
        </div>
      </div>
    </>
  );
}
