import { useEffect } from "react";
import React from "react";
import HomePage from "./components/HomePage";
import RoofingServices from "./components/RoofingServices";
import AboutUs from "./components/AboutUs";
import OurWork from "./components/OurWork";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import { Route, Routes } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async';

function App() {

  const helmetContext = {};
  
  return (
    <HelmetProvider context={helmetContext}>
      <Navbar/>
      <Routes>
        <Route exact path="/" element={<HomePage/>} />
        <Route exact path="/cambridge-roofing-services" element={<RoofingServices/>} />
        <Route exact path="/about-us" element={<AboutUs/>} />
        <Route exact path="/our-work" element={<OurWork/>} />
        <Route exact path="/contact" element={<Contact/>} />
      </Routes>
      <Footer/>
    </HelmetProvider>
  );
}

export default App;
