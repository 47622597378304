import React from "react";

export default function Hero() {
  return (
    <div className="bg-hero-bg-mobile lg:bg-hero-bg h-[80vh] bg-[#333] bg-blend-multiply relative bg-center bg-cover bg-no-repeat mt-[30px] lg:mt-[40px]">
      <div className="w-[calc(100vw_-_60px)] max-w-[900px] text-center absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 [&_*]:text-[#f8f8f8] pt-16 sm:pt-10">
        <h1 className="text-sm lg:text-base uppercase tracking-widest mb-5 font-medium"><a href="/cambridge-roofing-services" alt="Services we offer">Cambridge Roofing Services</a></h1>
        <h2 className="text-3xl sm:text-4xl lg:text-5xl mb-7 font-bold tracking-wider">Roofing Excellence, Built to Last.</h2>
        <p className="leading-6 lg:leading-7 mb-12 font-medium">We take pride in providing <a href="/cambridge-roofing-services" alt="Services we offer">top-quality roofing services</a> to homeowners and businesses alike. With years of experience our team our the best roofers in Cambridge, we are your trusted partner for all your roofing needs.</p>
        <a href="/contact" className="bg-[#ebc251] rounded-3xl py-2 px-6 inline-block text-center !text-black font-medium tracking-wide">CALL NOW FOR A FREE QUOTE</a>
      </div>
    </div>
  )
}