import React, { useEffect, useState } from "react";
import logo from './../../assets/logo.svg';
import burger from './../../assets/burger.svg';
import { NavLink } from 'react-router-dom';
const Navbar = () => {

  const menuDrawer = React.createRef();
  const menuWrapper = React.createRef();
  const menuBackground = React.createRef();

  handleCloseMenu()

  function handleShowMenu() {
    menuWrapper.current.classList.remove('-translate-x-full');
    menuBackground.current.classList.replace('opacity-0', 'opacity-40');
    menuDrawer.current.classList.remove('-translate-x-full');
  }

  function handleCloseMenu() {
    const menuDrawerElement = menuDrawer.current;
    const menuWrapperElement = menuWrapper.current;
    const menuBackgroundElement = menuBackground.current;
    if (!menuDrawerElement || !menuWrapperElement || !menuBackgroundElement) {
      return;
    }
    menuDrawerElement.classList.add('-translate-x-full');
    menuBackgroundElement.classList.add('opacity-40', 'opacity-0');
    
    setTimeout(() => {
      menuWrapperElement.classList.add('-translate-x-full');
    }, 500);
    
  }

  function handleClick(e) {

    
    setTimeout(() => {
      handleCloseMenu()
    }, 200);
    
  }

  return (
    <>
      <div className="header flex flex-wrap absolute top-0 left-0 right-0 z-10">
        <div className="bg-[#4476C9] flex items-center justify-center padding-2 w-full h-[30px] lg:h-[40px]">
          <span className="text-sm lg:text-base tracking-wide uppercase font-bold text-white">Call us now <a href="tel:07591 122610" className="underline underline-offset-2" alt="Call us">07591 122610</a></span>
        </div>
        <div className="flex w-full gap-4 justify-between py-6 lg:py-3 px-5">
          <div className="lg:hidden">
            <div>
              <button 
                onClick={handleShowMenu} 
                className="mt-2"
                >
                <img src={burger} className="w-[28px]" alt="Mobile Menu"/>
              </button>
              <div
                ref={menuWrapper}
                className={
                  "-translate-x-full h-screen w-screen fixed left-0 top-0 bottom-0 right-0"
                }
                aria-hidden="true"
              >
                <div ref={menuDrawer} className="bg-white h-screen w-[80vw] relative z-30 pt-10 duration-700 transition-all -translate-x-full">
                  <nav className="py-4 [&_a]:text-black [&_a]:font-normal [&_a]:py-4 [&_a]:px-6 [&_a]:block [&_a]:border-b">
                    <NavLink
                      to="/"
                      exact
                      activeClassName="underline"
                      onClick={handleClick}
                    >
                      Home
                    </NavLink>
                    <NavLink
                      to="/cambridge-roofing-services"
                      exact
                      activeClassName="underline"
                      onClick={handleClick}
                    >
                      Roofing Services
                    </NavLink>
                    <NavLink
                      to="/about-us"
                      exact
                      activeClassName="underline"
                      onClick={handleClick}
                    >
                      About us
                    </NavLink>
                    <NavLink
                      to="/contact"
                      exact
                      activeClassName="underline"
                      onClick={handleClick}
                    >
                      Contact
                    </NavLink>
                  </nav>
                </div>
                <div
                  ref={menuBackground}
                  onClick={handleCloseMenu}
                  className="absolute top-0 left-0 right-0 bottom-0 bg-black opacity-0 z-20 w-full transition-opacity duration-500"
                ></div>
              </div>
            </div>
          </div>
          <div className="flex-1 lg:flex-none lg:w-1/2 flex content-center">
            <a href="/" className="text-white flex items-center gap-4 w-full [&_img]:w-14 justify-end lg:justify-start" alt="Cambridge Aspect Roofing">
              <img src={logo} alt="Cambridge Aspect Roofing"/>
              <h1 className="text-1xl lg:text-[1.3rem] font-light">Cambridge Aspect Roofing</h1>
            </a>
          </div>
          <div className="flex flex-1 justify-center hidden lg:block">
            <nav className="flex gap-10 py-4 [&_a]:text-white [&_a]:tracking-wide [&_a]:font-normal [&_a]:py-1 [&_a]:block [&_a.active]:border-b [&_a:hover]:border-b justify-end">
              <NavLink
                to="/"
                exact
                activeClassName="underline"
              >
                Home
              </NavLink>
              <NavLink
                to="/cambridge-roofing-services"
                exact
                activeClassName="underline"
              >
                Roofing Services
              </NavLink>
              <NavLink
                to="/about-us"
                exact
                activeClassName="underline"
              >
                About us
              </NavLink>
              <NavLink
                to="/contact"
                exact
                activeClassName="underline"
              >
                Contact
              </NavLink>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
