import React from "react";
import logo from './../../assets/logo.svg';
const Footer = () => {

  return (
    <>
      <footer className="bg-[#4476C9] px-6 lg:px-10 block">
        <div className="flex flex-wrap justify-between pt-5 items-center">
          <div className="w-full lg:w-[450px] mt-1">
            <div className="text-white flex items-center gap-4 w-full [&_img]:w-14 justify-center lg:justify-start">
              <img src={logo} alt="Cambridge Aspect Roofing"/>
              <h2 className="text-1xl lg:text-2xl font-light">Cambridge Aspect Roofing</h2>
            </div>
          </div>
          <div className="flex-1 justify-center lg:justify-end flex text-white pt-6 sm:pt-4 lg:pt-0">
            <p className="text-lg font-light flex items-center gap-3 justify-center lg:justify-end">Call us on <a href="tel:07591 122610" className="underline underline-offset-2" alt="Call us">07591 122610</a></p>
          </div>
        </div>
        <div className="flex justify-between border-t border-[#f9f9f9]/30 text-white text-xs font-light py-4 mt-10">
          <span>© Cambridge Aspect Roofing</span>
        </div>
      </footer>
    </>
  );
};

export default Footer;
