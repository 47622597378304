import React, { useState } from "react";


export default function OurWork() {

  return (
    <>
      <div className="bg-services-header h-[200px] md:h-[250px] lg:h-[40vh] bg-[#333] bg-blend-multiply relative bg-center bg-cover bg-no-repeat mt-[30px] lg:mt-[40px] p-10">
        <div className="absolute left-0 top-[calc(50%+20px)] -translate-y-1/2 text-center w-full">
          <h1 className="text-2xl sm:text-3xl md:text-5xl lg:text-5xl font-bold tracking-wider text-white">Our work</h1>
        </div>
      </div>
      <div className="max-w-[1200px] mx-auto mb-20  px-5 sm:px-10">
        <div className="py-5 md:py-10 lg:py-18 border-b border-black/20 mb-10">
          <h2 className="text-sm lg:text-base uppercase tracking-widest mb-5 font-medium">Full service, roofing experts</h2>
          <h1 className="text-3xl sm:text-4xl lg:text-5xl font-bold">Photos of our previous projects</h1>
        </div>
        <div className="md:grid md:grid-cols-2 lg:grid-cols-3 mb-20 gap-10">
          <div className="mb-10 md:md-0 group overflow-hidden relative rounded-lg overflow-hidden">
            <div className=" object-cover  ">
              <img src="https://roofworks-south.co.uk/wp-content/uploads/2020/11/services_0001_Roofing-and-building-works-in-Tunbridge-Wells-15-1024x768-1.jpg"/>
            </div>
            <div className="translate-y-[calc(100%-30px)] group-hover:translate-y-10 absolute top-0 p-4 bg-black/80 transition-all text-white h-[300px]">
              <h3 className="mb-4 font-bold text-lg leading-6">Flat Roofing</h3>
              <p className="text-sm">A Flat roof is low maintenance, relatively low-cost roofing solution which can be suited to a range of applications. There are now modern products which offer an extended lifespan and increased durability.</p>
            </div>
          </div>
          <div className="mb-10 md:md-0 group overflow-hidden relative rounded-lg overflow-hidden">
            <div className=" object-cover  ">
              <img src="https://roofworks-south.co.uk/wp-content/uploads/2020/11/services_0001_Roofing-and-building-works-in-Tunbridge-Wells-15-1024x768-1.jpg"/>
            </div>
            <div className="translate-y-[calc(100%-30px)] group-hover:translate-y-10 absolute top-0 p-4 bg-black/80 transition-all text-white h-[300px]">
              <h3 className="mb-4 font-bold text-lg leading-6">Flat Roofing</h3>
              <p className="text-sm">A Flat roof is low maintenance, relatively low-cost roofing solution which can be suited to a range of applications. There are now modern products which offer an extended lifespan and increased durability.</p>
            </div>
          </div>
          <div className="mb-10 md:md-0 group overflow-hidden relative rounded-lg overflow-hidden">
            <div className=" object-cover  ">
              <img src="https://roofworks-south.co.uk/wp-content/uploads/2020/11/services_0001_Roofing-and-building-works-in-Tunbridge-Wells-15-1024x768-1.jpg"/>
            </div>
            <div className="translate-y-[calc(100%-30px)] group-hover:translate-y-10 absolute top-0 p-4 bg-black/80 transition-all text-white h-[300px]">
              <h3 className="mb-4 font-bold text-lg leading-6">Flat Roofing</h3>
              <p className="text-sm">A Flat roof is low maintenance, relatively low-cost roofing solution which can be suited to a range of applications. There are now modern products which offer an extended lifespan and increased durability.</p>
            </div>
          </div>
          <div className="mb-10 md:md-0 group overflow-hidden relative rounded-lg overflow-hidden">
            <div className=" object-cover  ">
              <img src="https://roofworks-south.co.uk/wp-content/uploads/2020/11/services_0001_Roofing-and-building-works-in-Tunbridge-Wells-15-1024x768-1.jpg"/>
            </div>
            <div className="translate-y-[calc(100%-30px)] group-hover:translate-y-10 absolute top-0 p-4 bg-black/80 transition-all text-white h-[300px]">
              <h3 className="mb-4 font-bold text-lg leading-6">Flat Roofing</h3>
              <p className="text-sm">A Flat roof is low maintenance, relatively low-cost roofing solution which can be suited to a range of applications. There are now modern products which offer an extended lifespan and increased durability.</p>
            </div>
          </div>
          <div className="mb-10 md:md-0 group overflow-hidden relative rounded-lg overflow-hidden">
            <div className=" object-cover  ">
              <img src="https://roofworks-south.co.uk/wp-content/uploads/2020/11/services_0001_Roofing-and-building-works-in-Tunbridge-Wells-15-1024x768-1.jpg"/>
            </div>
            <div className="translate-y-[calc(100%-30px)] group-hover:translate-y-10 absolute top-0 p-4 bg-black/80 transition-all text-white h-[300px]">
              <h3 className="mb-4 font-bold text-lg leading-6">Flat Roofing</h3>
              <p className="text-sm">A Flat roof is low maintenance, relatively low-cost roofing solution which can be suited to a range of applications. There are now modern products which offer an extended lifespan and increased durability.</p>
            </div>
          </div>
          <div className="mb-10 md:md-0 group overflow-hidden relative rounded-lg overflow-hidden">
            <div className=" object-cover  ">
              <img src="https://roofworks-south.co.uk/wp-content/uploads/2020/11/services_0001_Roofing-and-building-works-in-Tunbridge-Wells-15-1024x768-1.jpg"/>
            </div>
            <div className="translate-y-[calc(100%-30px)] group-hover:translate-y-10 absolute top-0 p-4 bg-black/80 transition-all text-white h-[300px]">
              <h3 className="mb-4 font-bold text-lg leading-6">Flat Roofing</h3>
              <p className="text-sm">A Flat roof is low maintenance, relatively low-cost roofing solution which can be suited to a range of applications. There are now modern products which offer an extended lifespan and increased durability.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
