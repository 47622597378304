import React from "react";
import houseRoof from './../../assets/house-roof.svg';
import commercialRoof from './../../assets/commercial-roof.svg';
import roofRepairs from './../../assets/roof-repair.svg';

export default function Services() {
  return (
    <div className="bg-[#EAF3FB] py-10 lg:py-18">
      <div className="text-center px-4">
        <h3 className="text-3xl sm:text-4xl lg:text-5xl mb-5 font-bold tracking-wide">Our services<br/>from start to finish</h3>
        <p className="leading-7 mb-12 font-bold text-lg">We offer a wide range of <a href="/services">roofing services</a> to meet all your roofing requirements.</p>
      </div>
      <div className="no-scrollbar overflow-y-scroll flex lg:overflow-auto lg:grid lg:grid-cols-3 gap-5 max-w-5xl mx-auto w-full px-5 snap-x scroll-pl-5">
        <div className="min-w-[300px] flex flex-wrap bg-white rounded-lg gap-x-5 gap-y-5 justify-between mb-5 lg:mb-0 p-4 items-start content-start snap-center">
          <div className="w-full lg:w-14 pt-1 flex justify-start pb-4 h-[80px] lg:h-auto">
            <img src={houseRoof} className="w-12 h-14 lg:w-full lg:h-auto" alt="Residential Roofing"/>
          </div>
          <div className="flex-1">
            <h3 className="mb-4 font-bold text-lg leading-6">Residential Roofing</h3>
            <p>Protect your home with our durable and aesthetically pleasing roofing solutions. From installations to repairs, we offer a range of services tailored to meet the unique needs of homeowners.</p>
          </div>
        </div>
        <div className="min-w-[300px] flex flex-wrap bg-white rounded-lg gap-x-5 gap-y-5 justify-between mb-5 lg:mb-0 p-4 items-start content-start">
          <div className="w-full lg:w-14 pt-1 flex justify-start pb-4 h-[80px] lg:h-auto">
            <img src={commercialRoof} className="w-12 h-14 lg:w-full lg:h-auto"  alt="Commercial Roofing"/>
          </div>
          <div className="flex-1">
            <h3 className="mb-4 font-bold text-lg leading-6">Commercial Roofing</h3>
            <p>Ensure your business is safeguarded with our expert commercial roofing services. We provide installations, maintenance, and repairs for a variety of commercial roofing systems, ensuring minimal disruption to your operations.</p>
          </div>
        </div>
        <div className="min-w-[300px] flex flex-wrap bg-white rounded-lg gap-x-5 gap-y-5 justify-between mb-5 lg:mb-0 p-4 items-start content-start">
          <div className="w-full lg:w-14 pt-1 flex justify-start pb-4 h-[80px] lg:h-auto">
            <img src={roofRepairs} className="w-12 h-14 lg:w-full lg:h-auto" alt="Roof Repairs and Maintenance"/>
          </div>
          <div className="flex-1">
            <h3 className="mb-4 font-bold text-lg leading-6">Roof Repairs and Maintenance</h3>
            <p>Keep your roof in top condition with our comprehensive repair and maintenance services. Whether it’s a minor leak or major damage, our skilled team is ready to restore your roof’s integrity.</p>
          </div>
        </div>
      </div>
    </div>
  )
}