import React, { useState } from "react";
import { Seo } from './Seo';
import emailjs from '@emailjs/browser';
import { Helmet } from 'react-helmet-async';

export default class extends React.Component {
  
  constructor(props) {
    super(props);
    
    this.state = {
      name: "",
      email: "",
      telephone: "",
      subject: "",
      message: "",
      success: false
    };

    this.form = React.createRef();

  }

  handleInputChange = (event) => {
    event.preventDefault();
    const target = event.target;
    const name = target.name;
    const value = target.value;
    this.setState({ [name]: value });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    emailjs
      .sendForm('service_r5bviyi', "template_fbd5858", this.form.current, {
        publicKey: 'wz4LipO_3T-LIVb8l',
      })
      .then(
        () => {
          this.setState({ success: true });
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  }


  render() {
    return (
      <>
        <Helmet>
          <title>Cambridge Aspect Roofing | Contact Us</title>
          <meta name='description' content='Contact Us | Your roofing experts based in Cambridge' />
          <link rel="canonical" href={"https://www.cambridgeaspectroofing.co.uk/contact-us"} />
        </Helmet>
        <div className="bg-services-header h-[200px] md:h-[250px] lg:h-[40vh] bg-[#333] bg-blend-multiply relative bg-center bg-cover bg-no-repeat mt-[30px] lg:mt-[40px] p-10">
          <div className="absolute left-0 top-[calc(50%+20px)] -translate-y-1/2 text-center w-full">
            <h1 className="text-2xl sm:text-3xl md:text-5xl lg:text-5xl font-bold tracking-wider text-white">Contact us</h1>
          </div>
        </div>
        <div className="max-w-[1200px] mx-auto mb-20 px-5 sm:px-10">
          <div className="py-5 md:py-10 lg:py-18 border-b border-black/20 mb-10">
            <h2 className="text-sm lg:text-base uppercase tracking-widest mb-5 font-medium">Get in touch with our friendly team today</h2>
            <h1 className="text-3xl sm:text-4xl lg:text-5xl font-bold">Send us a message and we will get back to you</h1>
          </div>
          <div className="lg:grid lg:grid-cols-2 lg:gap-10">
            <div className="mb-20 lg:mb-0">
              {this.state.success === true &&
                <p className="bg-black/20 block mb-10 font-bold p-2 rounded-lg">Thank you for sending us a message, we will get back to you as soon as possible</p>
              }
              <form ref={this.form} onSubmit={this.handleSubmit}>
                <div className="mb-4 md:flex md:gap-5 md:justify-between">
                  <div className="flex-1 mb-4 md:mb-0">
                    <label for="name" class="block mb-2 text-sm font-medium">Name</label>
                    <input type="text" id="name" class="bg-[#f8f8f8] w-full p-3 rounded-lg border border-[#ccc]" placeholder="Your name" required name="name" onChange={this.handleInputChange}/>
                  </div>
                  <div className="flex-1">
                    <label for="telephone" class="block mb-2 text-sm font-medium">Phone number</label>
                    <input type="telephone" id="telephone" class="bg-[#f8f8f8] w-full p-3 rounded-lg border border-[#ccc]" placeholder="Your phone number" required name="telephone" onChange={this.handleInputChange}/>
                  </div>
                </div>
                <div className="mb-4">
                    <label for="email" class="block mb-2 text-sm font-medium">Email</label>
                    <input type="email" id="email" class="bg-[#f8f8f8] w-full p-3 rounded-lg border border-[#ccc]" placeholder="Your email address" required name="email" onChange={this.handleInputChange}/>
                </div>
                <div className="mb-4">
                    <label for="subject" class="block mb-2 text-sm font-medium">Subject</label>
                    <input type="text" id="subject" class="bg-[#f8f8f8] w-full p-3 rounded-lg border border-[#ccc]" placeholder="Let us know how we can help you" name="subject" onChange={this.handleInputChange}/>
                </div>
                <div class="sm:col-span-2 mb-10">
                    <label for="message" class="block mb-2 text-sm font-medium">Your message</label>
                    <textarea id="message" rows="6" class="bg-[#f8f8f8] w-full p-3 rounded-lg border border-[#ccc]" placeholder="Leave a comment..." name="message" onChange={this.handleInputChange}></textarea>
                </div>
                <button type="submit" class="py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-[#4476C9] w-full md:w-fit focus:ring-4 focus:outline-none">Send message</button>
              </form>
            </div>
            <div className="">
              <h3 className="mb-2 font-bold text-lg leading-6">Our address</h3>
              <p>Flamstead Close</p>
              <p>Cambridge</p>
              <p className="mb-10">CB1 3FE</p>
              <h3 className="mb-2 font-bold text-lg leading-6">Email us</h3>
              <p className="mb-10"><a href="mailto:contact@cambridgeaspectroofing.co.uk" className="underline underline-offset-2">contact@cambridgeaspectroofing.co.uk</a></p>
              <h3 className="mb-2 font-bold text-lg leading-6">Call us</h3>
              <p className="mb-10"><a href="tel:07591 122610" className="underline underline-offset-2">07591 122610</a></p>
              <h3 className="mb-2 font-bold text-lg leading-6">Areas we cover</h3>
              <p>Our headquarters are in central Cambridge, allowing us to service all of Cambridgeshire and London. If you're located outside these areas and require Roofing Services, please don't hesitate to reach out. We're always happy to assist and provide advice at no cost.</p>
            </div>
          </div>
        </div>
      </>
    )
  }
}
