import React from "react";
import Hero from './Homepage/Hero';
import Services from './Homepage/Services';
import star from './../assets/star.svg';
import { Helmet } from 'react-helmet-async';
export default function HomePage() {

  const scriptData = JSON.stringify({
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Cambridge Roofing Services",
    "url": "https://www.cambridgeaspectroofing.co.uk",
    "logo": "https://www.cambridgeaspectroofing.co.uk/static/media/logo.cdcce8d031456ce2acedabeef8c5127e.svg",
    "contactPoint": {
      "@type": "ContactPoint",
      "telephone": "07591122610",
      "contactType": "Customer Service",
      "areaServed": "GB",
      "availableLanguage": "English"
    }
  })

  return (
    <>
      <Helmet>
          <title>Cambridge Aspect Roofing | Cambridge Roofing Services</title>
          <meta name='description' content='Cambridge Roofing Services | New roofs, roof repairs, gutter cleaning services and more. Call us today for a free quote.' />
          <script type="application/ld+json">{scriptData}</script>
          <link rel="canonical" href={"https://www.cambridgeaspectroofing.co.uk"} />
      </Helmet> 
      <Hero/>
      <Services/>
      <div className="bg-white pt-20 lg:py-20 lg:px-10 lg:grid lg:grid-cols-2 max-w-5xl mx-auto w-full gap-24">
        <div className="mb-20 lg:mb-0 px-10 lg:px-0">
          <div className="">
            <span className="text-lg font-bold mb-6 black">Why Choose Us?</span>
            <span className="text-3xl sm:text-4xl lg:text-5xl mb-7 font-bold tracking-wider block leading-[39px] md:leading-[55px]">Quality Craftsmanship</span>
            <p className="leading-6 mb-6">We use only the highest quality materials and the latest techniques to ensure your roof is built to last. Our Cambridge based team of experienced roofers is dedicated to delivering exceptional workmanship on every project.</p>
            <ul className="[&_li]:mb-6">
              <li><strong className="text-lg font-bold">Customer Satisfaction</strong><br/>Your satisfaction is our top priority. We work closely with you throughout the process, from initial consultation to project completion, ensuring your vision and needs are met with precision.</li>
              <li><strong className="text-lg font-bold">Competitive Pricing</strong><br/>We offer transparent and competitive pricing, providing you with the best value for your investment. No hidden fees, no surprises—just honest, fair pricing.</li>
              <li><strong className="text-lg font-bold">Licensed and Insured</strong><br/>Fully licensed and insured, giving you peace of mind knowing that your property is in safe hands.</li>
            </ul>
          </div>
        </div>
        <div className="">
          <div className="bg-[#EAF3FB] p-9 lg:p-6 ">
            <div className="pt-2 mb-10 after:content-[''] after:h-px after:w-[100px] after:bg-[#ccc] after:absolute relative after:inset-x-1/2 after:-translate-x-1/2 pb-8 after:bottom-0 text-center">
              <div className="flex justify-center pb-5 gap-2 text-[#ffbf00]">
                <img src={star} alt="5 star rating"/>
                <img src={star} alt="5 star rating" />
                <img src={star} alt="5 star rating" />
                <img src={star} alt="5 star rating" />
                <img src={star} alt="5 star rating" />
              </div>
              <span className="font-bold mb-3 block text-lg">Charles Marsh</span>
              <p>Did a fantasic job with our new flat roof, really professional would recommend.</p>
            </div>
            <div className="mb-10 after:content-[''] after:h-px after:w-[100px] after:bg-[#ccc] after:absolute relative after:inset-x-1/2 after:-translate-x-1/2 pb-8 after:bottom-0 text-center">
              <div className="flex justify-center pb-5 gap-2 text-[#ffbf00]">
                <img src={star} alt="5 star rating" />
                <img src={star} alt="5 star rating" />
                <img src={star} alt="5 star rating" />
                <img src={star} alt="5 star rating" />
                <img src={star} alt="5 star rating" />
              </div>
              <span className="font-bold mb-3 block text-lg">John Morals</span>
              <p>Cambridge Aspect Roofing did an outstanding job on our new roof. The team was professional, punctual, and the quality of their work was top-notch. They provided a detailed estimate and stuck to it, with no hidden costs. I highly recommend them for any roofing needs!</p>
            </div>
            <div className="mb-4 text-center">
              <div className="flex justify-center pb-5 gap-2 text-[#ffbf00]">
                <img src={star} alt="Review star" width="22" height="21"/>
                <img src={star} alt="Review star" />
                <img src={star} alt="Review star" />
                <img src={star} alt="Review star" />
                <img src={star} alt="Review star" />
              </div>
              <span className="font-bold mb-3 block text-lg">Emily Johnson</span>
              <p>I am extremely satisfied with the service provided by Cambridge Aspect Roofing. From the initial consultation to the final inspection, the team was courteous, knowledgeable, and efficient. The quality of the materials used and the craftsmanship were exceptional. I would highly recommend them to anyone in need of roofing services.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
