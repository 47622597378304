import React, { useState } from "react";
import roofCleaning from './../assets/roof-cleaning.png';
import fascias from './../assets/fascias.jpeg';
import gutterCleaning from './../assets/gutter-cleaning.jpg';
import flatRoof from './../assets/flat-roof.jpg';
import { Helmet } from 'react-helmet-async';

export default function RoofingServices() {

  return (
    <>
      <Helmet>
          <title>Cambridge Aspect Roofing | Cambridge Roofing Services</title>
          <meta name='description' content='New roofs, roof repairs, gutter cleaning services and more from your expert Cambridge roofing company. Contact us for a free no obligation quote.' />
          <link rel="canonical" href={"https://www.cambridgeaspectroofing.co.uk/cambridge-roofing-services"} />
      </Helmet> 
      <div className="bg-services-header h-[200px] md:h-[250px] lg:h-[40vh] bg-[#333] bg-blend-multiply relative bg-center bg-cover bg-no-repeat mt-[30px] lg:mt-[40px] p-10">
        <div className="absolute left-0 top-[calc(50%+20px)] -translate-y-1/2 text-center w-full">
          <h1 className="text-2xl sm:text-3xl md:text-5xl lg:text-5xl font-bold tracking-wider text-white">Cambridge Roofing Services</h1>
        </div>
      </div>
      <div className="max-w-[1200px] mx-auto mb-20 px-5 sm:px-10">
        <div className="py-5 md:py-10 lg:py-18 border-b border-black/20 mb-10">
          <h2 className="text-sm lg:text-base uppercase tracking-widest mb-3 md:mb-5 font-medium">Full service, roofing experts</h2>
          <h1 className="text-3xl sm:text-4xl lg:text-5xl font-bold">Some of the many services we offer</h1>
        </div>
        <div className="md:grid md:grid-cols-2 lg:grid-cols-3 mb-20 gap-10">
          <div className="mb-10 md:md-0">
            <div className="rounded-lg  overflow-hidden mb-5 h-[190px] relative">
              <img src={flatRoof} className="object-cover absolute -translate-y-2/4 top-2/4" alt="Flat roofing"/>
            </div>
            <h3 className="mb-4 font-bold text-lg leading-6">Flat Roofing</h3>
            <p>A Flat roof is low maintenance, relatively low-cost roofing solution which can be suited to a range of applications. There are now modern products which offer an extended lifespan and increased durability.</p>
          </div>
          <div className="mb-10 md:md-0">
            <div className="rounded-lg  overflow-hidden mb-5 h-[190px] relative">
              <img src={gutterCleaning} className="object-cover absolute -translate-y-2/4 top-2/4" alt="Gutter Cleaning Services"/>
            </div>
            <h3 className="mb-4 font-bold text-lg leading-6">Gutter Cleaning Services</h3>
            <p>Gutter cleaning is rarely considered, as it’s generally out of sight and out of mind, however a blocked down-pipe can lead to relatively serious consequences which usually is followed by a hefty repair bill.</p>
          </div>
          <div className="mb-10 md:md-0">
            <div className="rounded-lg  overflow-hidden mb-5 h-[190px] relative">
              <img src={fascias} className="object-cover absolute -translate-y-2/4 top-2/4" alt="Gutters, Fascias & Soffits"/>
            </div>
            <h3 className="mb-4 font-bold text-lg leading-6">Gutters, Fascias & Soffits</h3>
            <p>Like fascias, soffits should be checked for any signs of damage or wear. Proper ventilation should be ensured to maintain attic health.</p>
          </div>
          <div className="mb-10 md:md-0">
            <div className="rounded-lg  overflow-hidden mb-5 h-[190px] relative">
              <img src={flatRoof} className="object-cover absolute -translate-y-2/4 top-2/4" alt="New Roofs"/>
            </div>
            <h3 className="mb-4 font-bold text-lg leading-6">New Roofs</h3>
            <p>When selecting a new roof, consider factors such as the type and color of the tiles, as these can either enhance or detract from your home's appearance. If your home is terraced or semi-detached, you may also want to match the neighboring roof for a cohesive look.</p>
          </div>
          <div className="mb-10 md:md-0">
            <div className="rounded-lg  overflow-hidden mb-5 h-[190px] relative">
              <img src={roofCleaning} className="object-cover absolute -translate-y-2/4 top-2/4" alt="Gutter Cleaning Services"/>
            </div>
            <h3 className="mb-4 font-bold text-lg leading-6">Roof Cleaning Services</h3>
            <p>Roof cleaning is crucial as it not only enhances the appearance of your property but also safeguards the roof from damage, reducing the risk of needing a full roof replacement at a significantly lower cost.</p>
          </div>
          <div className="mb-10 md:md-0 bg-[#f8f8f8] rounded-lg flex flex-wrap items-center content-center justify-center p-5">
            <p className="text-black font-bold text-lg text-center mb-10 w-full">Contact us for a free no<br/> obligation quote</p>
            <p className="text-black font-bold text-lg text-center mb-2 w-full"><a href="tel:07591 122610">07591 122610</a></p>
            <p className="text-black font-bold text-lg text-center mb-2 w-full">or</p>
            <p className="text-black font-bold text-lg text-center w-full"><a href="/contact" alt="Contact us">Send us a message</a></p>
          </div>
        </div>
      </div>
    </>
  );
}
